@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  padding-bottom: 9.6rem;

  @include respond(tab-port) {
    padding-bottom: 6.4rem;
  }
}

.title {
  padding-block: 3.2rem;
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.5rem;
  text-align: center;
  color: $color-dark-grey-secondary;

  @include respond(tablet) {
    padding-block: 4rem;
    text-align: left;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-dark-grey-secondary;
}

.underline {
  text-decoration: underline;
}

.pageContent {
  max-width: 74rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  white-space: pre-wrap;

  & h1, h2, h3, h4, h5, h6 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: $color-dark-grey-secondary;

    @include respond(tablet) {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }

  & img {
    object-fit: cover;
    width: 100%;
  }

  & a {
    color: $color-dark-grey-secondary;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: none;
    }
  }

  & strong {
    font-weight: 600;
  }

  & ul, ol {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  & li {
    margin-left: 2rem;
    white-space: normal;
  }

  @include respond(tablet) {
    max-width: 100%;
    font-size: 1.9rem;
    line-height: 2.8rem;
  }
}
