@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.breadcrumb, .lastBreadcrumb {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $color-dark-grey-secondary;
  text-decoration: none;

  @include respond(tab-port) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

.lastBreadcrumb {
  font-weight: 500;
  cursor: default;
}

.separator {
  color: $color-dark-grey-secondary;
  width: 1.5rem;
  height: 1.5rem;
}

.backToHomepage {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
